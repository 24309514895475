@import "../../assets/style/variables";
@import "../../assets/style/global.scss";

.navbar {
  height: 80px;
  width: 100%;
  padding: 20px 90px 15px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: $background-color-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .hamburger-menu {
    display: none;
  }

  .logo {
    width: 48px;
    cursor: pointer;
  }

  &__list {
    display: flex;
    align-items: center;
    column-gap: 35px;

    &-link {
      font-size: 18px;
      line-height: 24px;

      a {
        color: $global-color-primary;
        transition: all 0.3s ease;

        &:hover {
          color: $global-color-secondary;
        }
      }
    }

    .resume {
      border: 1px solid $global-color-secondary;
      border-radius: 8px;
      padding: 8px 15px;
      transition: all 0.3s ease;
      cursor: pointer;

      a {
        color: $global-color-secondary;
      }

      &:hover {
        border-color: $global-color-hover;

        background: rgba($color: #fff, $alpha: 0.1);
      }
    }
  }
}

@media (max-width: 1400px) {
  .navbar {
    height: 60px;

    .logo {
      width: 36px;
    }

    &__list {
      column-gap: 30px;

      &-link {
        font-size: 16px;
        line-height: 24px;
      }

      .resume {
        padding: 5px 12px;
      }
    }
  }
}

@media (max-width: 650px) {
  .navbar { 
    padding-left: 30px;
    padding-right: 30px;
    ul {
      flex-direction: column;
      position: fixed;
      margin-top: 60px;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: #3E3E3E;
      z-index: 5;
      align-items: center;
      justify-content: center;
      li {
        margin: 20px 0;
      }
    }
    .hamburger-menu {
      display: block !important;
      font-size: 30px !important;
      color: inherit;
    }
  }
  .display-none {
    display: none !important;
  }
    
}

@import "../../assets/style/variables";
@import "../../assets/style/global.scss";

.footer {
  padding: 60px 90px;
  background: $background-color-secondary;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include mobile {
    flex-wrap: wrap;
  }

  &_logo {
    flex: 1;
    img {
      width: 50px;
    }

    @include tablet {
      width: 20%;
      flex: none;
    }
    @include mobile {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
  }

  &_content {
    flex: 1.5;
    display: flex;
    justify-content: space-between;
    @include nokia {
      flex: 0;
      width: 100%;
    }

    @include mobile {
      flex-wrap: wrap;
    }

    &-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      @include mobile {
        width: 100%;
      }
      @media (max-width: 450px) {
        width: 80%;
      }
      @include nokia {
      align-items: start;
      width: 100%;
      }

      h6 {
        color: $global-color-secondary;
        font-size: 20px;
        margin-bottom: 30px;
        text-align: center;
        font-family: $font-family-popins;
        @include nokia {
          display: inline-block;
          margin: 0 auto;
          margin-bottom: 20px;
        }
      }

      .footer-dates {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include nokia {
          width: 100%;
          white-space: nowrap;
          text-align: center;
        }
        li {
          margin-bottom: 16px;
         
          a {
            color: $global-color-primary;
            transition: all 0.2s ease;
            white-space: nowrap;
            @include nokia {
              white-space: unset;
            }
            @include tablet {
              white-space: unset;
            }
            // &:hover {
            //   color: $global-color-secondary;
            // }
          }
        }
      }

      .footer-socials {
        display: flex;
        align-items: center;
        margin-top: 30px;
        @include nokia {
          margin-left: 20%;
        }
        li {
          display: inline-block;
          margin-right: 10px;
          a {
            font-size: 25px;

            &:hover {
              i {
                color: $global-color-secondary;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../assets/style/_variables.scss";
@import "../../assets/style/global.scss";

@mixin a-underline {
    display: inline-block;
    position: relative;
    color: $global-color-secondary;
    overflow: hidden;
    top: 5px;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        display: block;
        height: 1px;
        bottom: 0.5px;
        left: -100%;
        opacity: 1;
        background-color: $global-color-secondary;
        transform-origin: bottom left;
        transition: all 0.25s ease;
    }
    &:hover:after {
        transform-origin: bottom left;
        left: 0;
        z-index: 10;
    }
}

.projects {
    padding-top: 50px;
    padding-bottom: 100px;
    height: 700px;
    margin-bottom: 100px;
    position: relative;
    overflow: hidden;
    width: 100%;
    @include mobile {
      height: 100%;
      min-height: 700px;
    }
    .project-header {
        font-size: 32px;
        font-weight: 600;
        font-style: normal;
        line-height: 35px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-left: 37%;
        margin-bottom: 80px;
        white-space: nowrap;

        @include mobile {
            font-size: 25px;
        }

        &::after {
            content: "";
            display: block;
            position: relative;
            top: 0px;
            width: 160px;
            height: 1px;
            background-color: $global-color-primary;
            margin-left: 20px;

            @include tablet {
                width: 0;
            }

            @include mobile {
                width: 0px;
            }
        }
    }

    .projects-box {
        width: 82%;
        height: 80%;
        margin: 0 auto;
        position: relative;
        display: flex;
        flex-direction: column;
        
        .slider {
            height: 350px;
            display: flex;
            position: absolute;
            left: 0;
            transition: all 1s ease-out;
            @include mobile {
              height: 100%;
              position: static;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
            }
            .slider-child {
                width: 100vw;
                @include mobile {
                  height: 350px;
                  margin-bottom: 50px;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-direction: column;
                }
                
            }
        }
        .project-blog {
            display: flex;
            width: 80%;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            margin-bottom: 100px;

            @include mobile {
                text-align: left;
                width: 100%;
              margin-bottom: 50px;
            }

            .project-left {
                flex: 4;
                height: 350px;
                // background: url("../../assets/img/project.jpg");
                background-size: cover;
                background-position: center;

                @include mobile {
                    filter: grayscale(100%);
                    z-index: 1;
                }

                @include mobile {
                    width: 100%;
                    flex: none;
                }
                a {
                    width: 100%;
                    height: 100%;
                    display: block;
                    background-color: rgba($color: $global-color-secondary, $alpha: 0.3);
                    transition: 0.3s ease-out;

                    @include mobile {
                        background-color: transparent;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                    @include mobile {
                        text-align: left;
                    }
                }
            }

            .project-right {
                flex: 3;
                @include mobile {
                    width: 100%;
                    height: 100%;
                    min-height: 360px;
                    flex: none;
                    margin-left: -100%;
                    background-color: #414141da;
                    z-index: 2;
                    background-size: cover;
                    background-position: center;
                    padding: 30px 40px;
                }
                .project-right-title {
                    margin: 10px 0px;
                    font-size: 13px;
                    font-weight: 400;
                    color: $global-color-secondary;
                }

                h3 {
                    color: $global-color-primary;
                    a {
                        color: inherit;
                        transition: 0.5s ease-out;
                        &:hover {
                            color: $global-color-secondary;
                        }
                    }
                }

                .project-decsription {
                    width: 130%;
                    margin-left: -30%;
                    background-color: #3c3c3c;
                    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
                    border-radius: 4px;
                    margin-top: 30px;
                    margin-bottom: 30px;
                    box-sizing: border-box;
                    z-index: 10;

                    @include tablet {
                        width: 160%;
                        margin-left: -60%;
                    }

                    @include mobile {
                        width: 100%;
                        margin: 0;
                        background-color: transparent;
                        box-shadow: none;
                    }

                    p {
                        padding: 25px;
                        font-size: 18px;
                        line-height: 23px;
                        color: #a8b2d1;
                        font-family: sans-serif;
                        background-color: transparent;
                        @include mobile {
                            font-size: 15px;
                            padding-left: 0;
                        }
                        a {
                            @include a-underline;
                        }
                    }
                }
                ul {
                    width: 140%;
                    margin-left: -40%;

                    @include mobile {
                        width: 100%;
                        margin: 0;
                    }

                    li {
                        display: inline-block;
                        color: #a8b2d1;
                        margin-left: 20px;
                        font-size: 13px;

                        @include mobile {
                            margin-left: 0;
                            margin-right: 20px;
                        }
                    }
                }

                .project-link {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    width: 100%;
                    margin-top: 20px;

                    @include mobile {
                        justify-content: start;
                    }

                    a {
                        margin-left: 20px;
                        font-size: 22px;
                        color: #a8b2d1;

                        @include mobile {
                            margin-left: 0;
                            margin-right: 20px;
                        }

                        &:hover {
                            i {
                                transition: 0.5s ease-out;
                                color: $global-color-secondary;
                            }
                        }
                    }
                }
            }
        }

        .flex-reverse {
            flex-direction: row-reverse;
            text-align: left;

            @include mobile {
                flex-direction: row;
            }

            .project-right {
                .project-decsription {
                    margin-left: 0;
                }
                ul {
                    margin-left: 0;
                    li {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
                .project-link {
                    justify-content: flex-start;
                    a {
                        margin-left: 0;
                        margin-right: 20px;
                    }
                }
            }
        }
        .latest-blog {
            margin: 0;
        }
    }

    .btn {
        background-color: transparent;
        border: 1px solid $global-color-secondary;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-items: center;
        margin-top: 30px;
        position: absolute;
        bottom: -80px;
        right: 0;
        z-index: 20;
        a {
            color: $global-color-secondary;
            display: block;
            padding: 12px 16px;
            &:hover {
                background-color: rgba($color: $global-color-secondary, $alpha: 0.2);
            }
        }
    }
    .arrow {
        height: 50px;
        width: 50px;
        font-size: 90px;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 50px);
        display: block;
        @include mobile {
            display: none;
        }

        &.left {
            left: -80px;
            // transform: rotateY(180deg);
        }

        &.right {
            right: -50px;
        }
    }
}


@import '../../assets/style/global.scss';

.blogs-container {
    width: 87%;
    margin: 0 auto;
}

.blogs {
    margin-top: 80px;

    .card-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 15px;
        @include tablet {
            justify-content: center;

        }
        .card {
            width: 32%;
            background-color: #212529;
            border-radius: 5px;
            min-width: 260px;
            @include tablet {
                width: 45%;
            }
            @include mobile {
                width: 90%;
            }
            @include nokia {
                width: 100%;
            }
            
            .card-child {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                margin: 24px;

                .card-title {
                    p {
                        margin-bottom: 5px;
                        color: #25cae7;
                    }
                }
                .card-body {
                    margin-bottom: 20px;
                    
                    a {
                        font-size: 18px;
                        line-height: 23px;
                        text-decoration: none;
                        font-weight: 700;
                        color: $global-color-primary;
                        &:hover {
                        text-decoration: underline;
                        }
                    }
                }

                .card-footer {
                   a {
                    font-size: 13px;
                    line-height: 20px;
                    color: $global-color-primary;
                    opacity: 0.7;
                    text-decoration: underline;
                    &:hover {
                        opacity: 1;
                    }
                   }
                }
            }
        }
    }

    h1 {
        font-size: 27px;
        font-weight: 700;
        line-height: 30px;
        padding-top: 48px;
        margin-bottom: 16px;
    }
}

